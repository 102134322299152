@font-face {
    font-family: morant;
    src: url(../fonts/morant-font/Morant-lgMB5.ttf);
  }

  @font-face {
    font-family: morant-heavy;
    src: url(../fonts/morant-font/Morant-lgMB5.ttf);
  }

  @font-face {
    font-family: Press Start 2P;
    src: url(../fonts/PressStart2P-Regular.ttf);
  }

.mainimg {
 height: 450px;
 margin-left: auto; 
 display: block;
 margin-right: auto;
 margin-bottom: 15px;
}

.container {
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 5%;
}

h2 {
    font-family: morant, Avenir;
    font-weight: 100;
    font-size: 25px;
    display: inline-block;
}

h2.title {
    display: inline;
}

button {
    font-family: morant, Avenir;
    font-weight: 100;
    border: none;
    outline: inherit;
    background: none;
    display: block;
    cursor: pointer;
    font-size: 25px;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

button:hover {
    color: gray;
}

.apple_button {
    height: 30px;
}

.text-container {
    width: 100%;
    height: 80px;
    display: block;
}

.body-container {
    font-family: morant;
    font-weight: 100;
    font-size: 25px;
}

.tab-container {
    font-family: morant, Avenir;
    font-weight: 100;
    font-size: 25px;
}

.spacer {
    width: 100%;
    height: 100px;
}

.label {
    font-family: morant, Avenir;
    font-weight: 100;
    font-size: 25px;
}

[type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  [type=radio]:checked + img {
    outline: 1px solid black;
    border-radius: 3px;
  }

.plant-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.plant-img {
    display: inline-block;
    width: 30%;
    margin-left: 3%
}

li {
    margin-bottom: 10px;
}

.athelas {
    color: #FC5378;
}

.daily {
    color: #ff9966;
}

.yale {
    color: #0f4d92;
}

.nfme {
    color: #9999ff;
}

.everything {
    color: #538cc6
}

.link {
    font-weight: 800;
}

.socials {
    width: 100%;
    height: 60px;
}

.condensed {
    font-family: Avenir, Arial;
}

.social-icons {
    display: inline-block;
    height: 60px;
    width: 60px;
    margin-right: 5px;
    transition: ease 0.5s;
}

.social-icons:hover {
    opacity: 0.7;
}

button.back {
    display: inline;
    margin: 0;
}

a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

.NFME {
    font-family: 'Press Start 2P';
}

.andermann {
    color: #DC143C;
}

.no-select {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

ul {
    margin-top: 5px;
    list-style: "* ";
}

ul.condensed {
    margin-top: 5px;
    list-style: square;
}

ul.intro-list {
    font-family: morant;
    font-size: 25px;
}

ul.intro-list.condensed {
    font-family: Avenir;
    font-size: 25px;
}

strike {
    font-family: morant;
    font-size: 25px;
    font-weight: 100;
}

strike.condensed {
    font-family: Avenir;
}

.project-container {
    width: 95%;
    margin-left: 5%;
}

.form-container {
    /* background-color: black; */
    padding: 10px 20px;
}

input[type=text], input[type=email]  {
    display: block;
    width: 100%;
    font-family: morant;
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-bottom: 1px solid black;
}

input[type=text]._2b0Yz {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0px;
}

.form-message {
    font-weight: 800;
}

.img-button {
    width: 100%;
}

input.message {
    height: 100px;
    padding: 0px;
}

.text-area {
    font-family: morant;
    font-size: 25px;
    color: black;
    width: 100%;
    margin-bottom: 10px;
    padding-top: 5px;
}

.text-area:focus {
    outline: none;
}

input[type=submit] {
    display: block;
    width: 100%;
    font-family: morant;
    font-size: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

input[type=text]:focus , input[type=email]:focus {
    border: none;
    outline: none;
    background-color: #f2f2f2;
    border-bottom: 2px solid black;
  }


@media only screen and (max-width: 1200px) {
    .container {
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;
        margin-top: 5%;
    }
    .mainimg {
        height: 400px;
        margin-left: auto; 
        display: block;
        margin-right: auto;
        margin-bottom: 15px;
        margin-top: 0px;
       }
    .text-container {
        width: 100%;
        height: 80px;
        display: block;
        margin-bottom: 0px;
    }

  }

@media only screen and (max-width: 600px) {
    .container {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 5%;
        margin-top: 30px;
    }
    .mainimg {
        height: 300px;
        margin-left: auto; 
        display: block;
        margin-right: auto;
        margin-bottom: 15px;
        margin-top: 100px;
       }
       .text-container {
        width: 100%;
        height: 80px;
        display: block;
        margin-bottom: 0px;
    }
    .plant-img {
        display: inline-block;
        width: 70%;
        margin-left: 15%;
    }
  }